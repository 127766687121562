<router-outlet></router-outlet>

<!-- default toast host element -->
<p-toast></p-toast>

<!-- toast host element for sw-update messages -->
<p-toast key="sw-update" position="top-center">
  <ng-template let-message pTemplate="message">
    <div class="sw-update-message-content">
      <h4>{{ message.summary }}</h4>
      <p-button class="h--40 w100 text-14 fw-600" (onClick)="reloadThePage()">
        {{ message.detail }}
      </p-button>
    </div>
  </ng-template>
</p-toast>

<app-landscape-overlay> </app-landscape-overlay>
