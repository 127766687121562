import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MessageService, PrimeNGConfig } from 'primeng/api'
// Bundle default language to the app
import { SwUpdate } from '@angular/service-worker'
import { AuthSession } from '@ti-platform/web/auth'
import { injectDestroy$, TitleController } from '@ti-platform/web/common'
import defaultLanguage from '@ti-platform/web/i18n/en.json'
import { DeviceService, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'
import { interval, takeUntil } from 'rxjs'

@Component({
  selector: 'app-fleet-web-root',
  templateUrl: 'fleet-web-app.component.html',
  styleUrl: 'fleet-web-app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetWebAppComponent implements OnInit {
  protected readonly ONE_HOUR_MILLISECONDS = 60 * 60 * 1000

  protected readonly authSession = inject(AuthSession)
  protected readonly branding = inject(BrandingService)
  protected readonly title = inject(TitleController)
  protected readonly deviceService = inject(DeviceService)
  protected readonly translate = inject(TranslateService)
  protected readonly primeNgConfig = inject(PrimeNGConfig)
  protected readonly messageService = inject(MessageService)
  protected readonly languageService = inject(LanguageService)
  protected readonly swUpdate = inject(SwUpdate)
  protected readonly destroy$ = injectDestroy$()

  public async ngOnInit() {
    this.configurePrimeNg()
    this.configureTranslations()
    this.initSwUpdateSubscription()
    // this.attemptToLockOrientationOnMobile()
    this.title.subscribe()
    this.applyBranding()
  }

  protected applyBranding() {
    this.branding.applyFavicon()
    this.branding.applyBranding()
  }

  protected configurePrimeNg() {
    this.primeNgConfig.ripple = true
  }

  protected configureTranslations() {
    this.translate.setTranslation('en', defaultLanguage)
    this.translate.setDefaultLang('en')
    this.languageService
      .getPrimengTranslations()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.primeNgConfig.setTranslation(res)
      })
  }

  protected initSwUpdateSubscription() {
    if (!this.swUpdate.isEnabled) {
      console.log('Update not enabled')
      return
    }
    this.swUpdate.versionUpdates.pipe(takeUntil(this.destroy$)).subscribe(async (e) => {
      switch (e.type) {
        case 'VERSION_DETECTED':
          console.info(`Downloading new app version: ${e.version.hash}`)
          break
        case 'VERSION_READY':
          console.info(`Current app version: ${e.currentVersion.hash}`)
          console.info(`New app version ready for use: ${e.latestVersion.hash}`)
          setTimeout(() => this.showUpdateDialog(), 5000)
          break
        case 'VERSION_INSTALLATION_FAILED':
          console.warn(`Failed to install app version '${e.version.hash}': ${e.error}`)
          break
      }
    })

    // Check for updates every hour
    interval(this.ONE_HOUR_MILLISECONDS).subscribe(async () => {
      try {
        const updateFound = await this.swUpdate.checkForUpdate()
        if (updateFound) {
          console.log('A new version is available.')
          await this.showUpdateDialog()
        }
      } catch (err) {
        console.error('Failed to check for updates:', err)
      }
    })
  }

  protected async showUpdateDialog() {
    console.info(`Request user update the app`)
    // eslint-disable-next-line no-case-declarations
    const [summary, detail] = await Promise.all([
      this.languageService.translate('sw-update.new-version'),
      this.languageService.translate('sw-update.install-btn'),
    ])
    this.messageService.clear('sw-update')
    this.messageService.add({
      summary,
      detail,
      key: 'sw-update',
      closable: true,
      life: this.ONE_HOUR_MILLISECONDS,
    })
  }

  protected async reloadThePage() {
    await this.swUpdate.activateUpdate()
    window.location.reload()
  }

  // Lock orientation works only in fullscreen mode
  protected attemptToLockOrientationOnMobile() {
    if (this.deviceService.isMobile()) {
      const desiredOrientation = 'portrait'
      try {
        // @ts-expect-error void
        if (typeof screen.lockOrientation === 'function') {
          // @ts-expect-error void
          screen.lockOrientation(desiredOrientation)
        }

        // @ts-expect-error void
        if (typeof screen.orientation.lock === 'function') {
          // @ts-expect-error void
          screen.orientation.lock(desiredOrientation)
        }
      } catch (error) {
        console.error(`Cannot lock orientation`, error)
      }
    }
  }
}
