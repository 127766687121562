import { Injectable } from '@angular/core'
import { BaseUserSettingsModel } from '@ti-platform/web/user'

@Injectable()
export class FleetUserSettingsModel extends BaseUserSettingsModel {
  protected saveName(value: string) {
    return this.api.fleet.user.setName(value)
  }

  protected savePhotoBase64(value: string, contentType: string) {
    return this.api.fleet.user.setPhotoBase64(value, contentType)
  }

  protected removePhoto() {
    return this.api.fleet.user.removePhoto()
  }
}
