<section class="sidebar" [ngClass]="{ hidden: sidebarService.isHidden() }">
  <app-sidebar
    [brandingData]="logoService.branding$ | async"
    [collapsed]="sidebarService.isCollapsed()"
  ></app-sidebar>
  <div class="toggle-icon clickable" (click)="sidebarService.collapse()">
    <app-icon-chevron
      [direction]="sidebarService.isCollapsed() ? 'right' : 'left'"
      [width]="20"
      [height]="20"
      [color]="'var(--color-gray-500)'"
    ></app-icon-chevron>
  </div>
</section>

<div
  class="backdrop"
  [ngClass]="{
    hidden: sidebarService.isHidden() && (filterPanelService.isPanelOpened$ | async) === false,
  }"
  (click)="hideOpenedPanel()"
></div>

<section class="content">
  <router-outlet></router-outlet>
</section>
