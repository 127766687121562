import { Component, inject } from '@angular/core'
import { BaseSignInComponent } from '@ti-platform/web/auth/components/base-sign-in.component'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrl: 'sign-in.component.scss',
})
export class SignInComponent extends BaseSignInComponent {
  protected readonly logoService = inject(BrandingService)

  public override async ngOnInit() {
    await super.ngOnInit()
    await this.logoService.applyFavicon()
  }
}
