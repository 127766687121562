// Angular
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, inject, ModuleWithProviders, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { MessageService } from 'primeng/api'
import { DialogService } from 'primeng/dynamicdialog'
//Sentry
import * as Sentry from '@sentry/angular'
// ngx-translate
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
// Dependencies
// App
import { FleetWhiteLabelSettingsProvider } from '@ti-platform/fleet-web/app/services/white-label-settings.provider'
import environment from '@ti-platform/fleet-web/env'
import { ApiModule } from '@ti-platform/web/api/api.module'
import { AuthModule } from '@ti-platform/web/auth/auth.module'
import { CONFIG, WhiteLabelSettingsProvider } from '@ti-platform/web/common'
import { AppCommonModule } from '@ti-platform/web/common/common.module'
import { I18NConfigProvider, languageInitializerProvider } from '@ti-platform/web/ui-kit/i18n'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import { UserModule } from '@ti-platform/web/user/user.module'
import { AppRoutingModule } from './app-routing.module'
import { AppInitializer } from './app.initializer'
import {
  FleetWebAppComponent,
  LayoutComponent,
  SidebarComponent,
  SignInComponent,
} from './components'
import { AUTH_PROVIDERS, FleetI18NConfigProvider, LANG_PROVIDERS } from './services'

const ngModules = [
  // Angular
  BrowserModule,
  BrowserAnimationsModule,
  // ngx-translate
  HttpClientModule,
  TranslateModule.forRoot({
    compiler: {
      provide: TranslateCompiler,
      useClass: TranslateMessageFormatCompiler,
    },
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      deps: [HttpClient],
    },
  }),
  ServiceWorkerModule.register(`service-worker.${environment.envType}.js`, {
    enabled: true,
    registrationStrategy: 'registerWhenStable:30000',
  }),
]
const appModules = [
  ApiModule,
  AppRoutingModule,
  AppCommonModule,
  AuthModule,
  UserModule,
  UiKitModule,
]
const dependencyModules: ModuleWithProviders<NonNullable<unknown>>[] = []

@NgModule({
  imports: [...ngModules, ...appModules, ...dependencyModules],
  providers: [
    DialogService,
    MessageService,
    languageInitializerProvider,
    {
      provide: CONFIG,
      useValue: environment,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...AUTH_PROVIDERS,
    ...LANG_PROVIDERS,
    { provide: I18NConfigProvider, useClass: FleetI18NConfigProvider },
    { provide: WhiteLabelSettingsProvider, useClass: FleetWhiteLabelSettingsProvider },
  ],
  declarations: [FleetWebAppComponent, SignInComponent, LayoutComponent, SidebarComponent],
  bootstrap: [FleetWebAppComponent],
})
export class FleetWebAppModule {
  protected readonly initializer = inject(AppInitializer)

  constructor() {
    this.initializer.init()
  }
}
