import { inject, Injectable } from '@angular/core'
import { takeUntil } from 'rxjs'
import { AuthSession, Profile } from '@ti-platform/web/auth'
import { CONFIG, WhiteLabelSettingsProvider } from '@ti-platform/web/common'

@Injectable()
export class FleetWhiteLabelSettingsProvider extends WhiteLabelSettingsProvider {
  protected readonly profile = inject(Profile)
  protected readonly authSession = inject(AuthSession)
  protected readonly config = inject(CONFIG)

  public constructor() {
    super()

    this.authSession.state.onSignedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refresh())
  }

  protected override async getSettings() {
    try {
      const loggedIn = await this.authSession.isLoggedIn()
      if (loggedIn) {
        return this.api.fleet.whiteLabel.getCurrentUserSettings()
      } else if (
        this.config.envType !== 'local' &&
        this.config.fleetDefaultDomain !== window.location.hostname
      ) {
        return this.api.fleet.whiteLabel.getSettingsByDomainName(window.location.hostname)
      }
    } catch (e) {
      console.error(e)
    }

    return {
      name: 'Fleet App',
      domain: '',
      logoLargeUrl: '',
      logoCompactUrl: '',
      googleMapsKey: '',
    }
  }
}
