<app-lang-toggle class="lang-toggle"></app-lang-toggle>

<div class="full-screen-container">
  <div class="sign-container">
    <p-panel class="panel-primary w-420">
      <ng-template pTemplate="header">
        <header class="flex">
          <img
            *ngIf="logoService.branding$ | async as branding"
            class="logo"
            [src]="branding.logo.lg.href"
            alt=""
          />
        </header>
      </ng-template>

      <form [formGroup]="signInForm" (submit)="onSignIn()" class="p-fluid">
        <section class="inputs-section">
          <input
            class="h--40 mb-10"
            id="username"
            placeholder="{{ 'sign-in.username' | translate }}"
            formControlName="username"
            pInputText
            type="text"
          />

          <app-password-input
            [height]="40"
            formControlName="password"
            placeholder="{{ 'sign-in.password' | translate }}"
          ></app-password-input>

          <div class="errors-box">
            <p class="error-message">{{ errorMessage() }}</p>
          </div>
        </section>

        <div class="forgot-section text-14 fw-600 clickable" (click)="forgotPassword.open()">
          {{ 'sign-in.forgot_password' | translate }}
        </div>

        <p-button
          class="sign-in-section text-14 fw-700 h--40 w100"
          type="submit"
          [disabled]="signingIn()"
          [label]="'sign-in.heading' | translate"
          (onClick)="onSignIn()"
        ></p-button>

        <div class="remember-section flex text-14">
          <p-checkbox formControlName="rememberMe" [binary]="true" inputId="binary"></p-checkbox>
          {{ 'sign-in.remember_me' | translate }}
        </div>
      </form>
    </p-panel>
  </div>

  <app-forgot-password #forgotPassword></app-forgot-password>

  <div class="background-container"></div>
</div>
