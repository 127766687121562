import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { BrowserSessionStorage, RouterNavigationHistoryProvider } from '@ti-platform/web/common'
import { SidebarService } from '@ti-platform/web/ui-kit/layout'
import { FilterPanelService } from '@ti-platform/web/ui-kit/layout/components/filter-panel/filter-panel.service'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  protected readonly logoService = inject(BrandingService)
  protected readonly sidebarService = inject(SidebarService)
  protected readonly filterPanelService = inject(FilterPanelService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly routerNavigationHistoryProvider = inject(RouterNavigationHistoryProvider)

  public async ngOnInit() {
    if (!this.sessionStorage.getItem('sidebarCollapsed')) {
      if (window?.innerWidth < 1024) {
        this.sessionStorage.setItem('sidebarCollapsed', 'true')
        this.sidebarService.isCollapsed.set(true)
      } else {
        this.sessionStorage.setItem('sidebarCollapsed', 'false')
      }
    } else {
      this.sidebarService.isCollapsed.set(
        this.sessionStorage.getItem('sidebarCollapsed') === 'true',
      )
    }
  }

  public hideOpenedPanel() {
    if (!this.sidebarService.isHidden()) {
      this.sidebarService.hide()
    }
    this.filterPanelService.closePanel()
  }
}
