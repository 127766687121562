import { inject, Injectable } from '@angular/core'
import { firstValueFrom, map, Observable, shareReplay, takeUntil } from 'rxjs'
import { Profile } from '@ti-platform/web/auth'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { I18NConfigProvider } from '@ti-platform/web/ui-kit/i18n/services'
import { getSettingsByCountryCode, I18NConfig } from '@ti-platform/web/ui-kit/i18n'

@Injectable()
export class FleetI18NConfigProvider extends I18NConfigProvider {
  protected readonly profile = inject(Profile)
  protected readonly destroy$ = injectDestroy$()

  @Memoize()
  public get config$(): Observable<I18NConfig> {
    return this.profile.state.pipe(
      map((profile) => {
        const settings = getSettingsByCountryCode(profile.fleet?.country ?? 'US')
        return {
          ...settings,
          lang: profile.language,
          measurementSystem: profile.fleet?.measurementSystem ?? settings.measurementSystem,
        }
      }),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: true }),
    )
  }

  public getConfig(): Promise<I18NConfig> {
    return firstValueFrom(this.config$)
  }
}
