import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { Action, Resource } from '@ti-platform/contracts'
import { loggedInGuard, NewPasswordComponent, notLoggedInGuard } from '@ti-platform/web/auth'
import { allowOnEnv, checkAccess } from '@ti-platform/web/common'
import {
  AccessDeniedPageComponent,
  DesignSystemComponent,
  NotFoundPageComponent,
} from '@ti-platform/web/ui-kit/layout'
import { LayoutComponent, SignInComponent } from './components'

const routes: Routes = [
  // Main Layout
  {
    path: '',
    component: LayoutComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: 'fleet',
        loadChildren: () =>
          import('@ti-platform/web/fleet/fleet.module').then((m) => m.FleetModule),
        canActivate: [checkAccess(Resource.Vehicle, Action.View)],
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('@ti-platform/web/locations/locations.module').then((m) => m.LocationsModule),
        canActivate: [checkAccess(Resource.Location, Action.View)],
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('@ti-platform/web/alerts/alert.module').then((m) => m.AlertModule),
        canActivate: [checkAccess(Resource.Alert, Action.View)],
      },
      {
        path: 'video',
        loadChildren: () =>
          import('@ti-platform/web/video/video.module').then((m) => m.VideoModule),
        canActivate: [allowOnEnv(['local'])],
        // canActivate: [checkAccess(Resource.Video, Action.View)],
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('@ti-platform/web/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [checkAccess(Resource.Reports, Action.View)],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@ti-platform/web/fleet-settings/fleet-settings.module').then(
            (m) => m.FleetSettingsModule,
          ),
      },
      { path: '', redirectTo: 'fleet', pathMatch: 'full' },
    ],
  },

  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [notLoggedInGuard],
    data: { title: 'sign-in.heading' },
  },
  {
    path: 'change-password',
    component: NewPasswordComponent,
    data: { title: 'auth.change-password.title' },
  },
  {
    path: 'create-password/:secret',
    component: NewPasswordComponent,
    data: { title: 'auth.create-password.title' },
  },
  {
    path: 'reset-password/:secret',
    component: NewPasswordComponent,
    data: { title: 'auth.reset-password.title' },
  },
  {
    path: '___design-system',
    component: DesignSystemComponent,
    canActivate: [loggedInGuard],
    canMatch: [allowOnEnv(['local', 'dev'])],
  },

  { path: 'access-denied', component: AccessDeniedPageComponent },
  { path: '**', component: NotFoundPageComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
