import { inject, Injectable } from '@angular/core'
import { take } from 'rxjs'
import {
  BrowserLocalStorage,
  BrowserSessionStorage,
  CONFIG,
  injectDestroy$,
} from '@ti-platform/web/common'
import { AuthSession, LOGIN_AS_SESSION, Profile, PushClient } from '@ti-platform/web/auth'
import { ApiService } from '@ti-platform/web/api'
import * as Sentry from '@sentry/angular'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  protected readonly api = inject(ApiService)
  protected readonly config = inject(CONFIG)
  protected readonly profile = inject(Profile)
  protected readonly authSession = inject(AuthSession)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly pushManager = inject(PushClient)
  protected readonly messageService = inject(MessageService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly loginAsSession = inject(LOGIN_AS_SESSION, { optional: true })

  public async init() {
    this.authSession.state.onSignedOut$.subscribe(async () => {
      if (this.config.sentry.enabled) {
        Sentry.setUser(null)
      }
      try {
        if (!this.loginAsSession && this.pushManager.isRegistered()) {
          await this.pushManager.unassignDevice()
        }
      } catch (e) {
        console.log('Failed to unassign push device', e)
      }
      this.sessionStorage.clear()
      this.localStorage.clear()
    })
    this.authSession.state.currentUserAttributes$.pipe(take(1)).subscribe(async (user) => {
      if (this.config.sentry.enabled && user) {
        Sentry.setUser({
          id: user.sub,
          username: user.name,
          email: user.email,
        })
      }

      try {
        if (!this.loginAsSession && this.pushManager.isRegistered()) {
          await this.pushManager.subscribeToPushes()
        }
      } catch (e) {
        console.log('Failed to subscribe to pushes', e)
      }
    })

    if (!this.loginAsSession) {
      await this.refreshTimezone()
      this.authSession.state.onSignedIn$.subscribe(async () => {
        try {
          if (!this.pushManager.isRegistered()) {
            console.log('Registering pushes after signing in')
            await this.pushManager.register()
          } else {
            console.log('Pushes already registered - skipping registration')
          }
        } catch (e) {
          console.log('Failed to register pushes', e)
        }
      })
    }
  }

  protected async refreshTimezone() {
    this.profile.state.pipe(take(1)).subscribe(async (profile) => {
      if (profile) {
        try {
          const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if (browserTimezone && browserTimezone !== profile.timezone) {
            await this.api.fleet.user.setTimezone(browserTimezone)
          }
        } catch (e) {
          console.log('Failed to update timezone', e)
        }
      }
    })
  }
}
