import { Provider } from '@angular/core'
import { ForgotPasswordViewModel, NewPasswordViewModel } from '@ti-platform/web/auth'
import { FleetForgotPasswordViewModel } from './forgot-password.view-model'
import { FleetNewPasswordViewModel } from './new-password.view-model'

export * from './forgot-password.view-model'
export * from './new-password.view-model'

export const AUTH_PROVIDERS: Provider[] = [
  {
    provide: NewPasswordViewModel,
    useClass: FleetNewPasswordViewModel,
  },
  {
    provide: ForgotPasswordViewModel,
    useClass: FleetForgotPasswordViewModel,
  },
]
